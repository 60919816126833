'use client'
import React, { useState, useEffect, useRef } from 'react'
import { Button, TextField, Typography, Box, Paper, CircularProgress, IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { Send as SendIcon, Close as CloseIcon } from '@mui/icons-material'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import chatrobo from './chatrobo.png';

const notionBlack = '#191919'
const notionGrey = '#202020'
const lightGrey = '#333'
const offWhite = '#CFCFCF'

export default function TheCB({ identifier, timestamp }) {
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [loadingMedia, setLoadingMedia] = useState({})
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [dawMode, setDawMode] = useState(false)
  const [selectedDaw, setSelectedDaw] = useState('Ableton Live')
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true)

  const messagesEndRef = useRef(null)
  const messagesContainerRef = useRef(null)
  const timestampRef = useRef(null)

  const scrollToLastMessage = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  // Restore DAW selection
  useEffect(() => {
    const storedDaw = localStorage.getItem('selectedDaw')
    if (storedDaw) {
      setSelectedDaw(storedDaw)
    }
  }, [])

  // Convert timestamp
  useEffect(() => {
    const minutes = Math.floor(timestamp / 60)
    const seconds = timestamp % 60
    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedSeconds = String(seconds).padStart(2, '0')
    timestampRef.current = `${formattedMinutes}:${formattedSeconds}`
  }, [timestamp])

  // Initial message
  useEffect(() => {
    if (identifier) {
      setMessages([])
    }
    const user = JSON.parse(localStorage.getItem('user'))
    const displayName = user?.displayName || 'there'
    const firstName = displayName.split(' ')[0]
    
    const initialMessage = {
      role: 'assistant',
      type: 'text',
      content: `Hey ${firstName},\nHow can I help you?`
    }
    setMessages([initialMessage])
  }, [identifier])

  // Auto-scroll on new messages if allowed
  useEffect(() => {
    if (shouldAutoScroll) {
      scrollToLastMessage()
    }
  }, [messages, shouldAutoScroll])

  // Toggle shouldAutoScroll based on user scrolling
  useEffect(() => {
    const container = messagesContainerRef.current

    const handleScroll = () => {
      if (!container) return
      const isAtBottom = container.scrollHeight - container.scrollTop - container.clientHeight <= 5
      if (isAtBottom && !shouldAutoScroll) {
        setShouldAutoScroll(true)
      } else if (!isAtBottom && shouldAutoScroll) {
        setShouldAutoScroll(false)
      }
    }

    if (container) {
      container.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (container) container.removeEventListener('scroll', handleScroll)
    }
  }, [shouldAutoScroll])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!input.trim()) return

    const userMessage = { role: 'user', type: 'text', content: input }
    setMessages((prev) => [...prev, userMessage])
    setInput('')
    setIsLoading(true)

    try {
      const messageHistory = messages
        .filter((message) => message.role === 'user' || message.role === 'assistant')
        .map((message) => ({ role: message.role, content: message.content }))

      const user = JSON.parse(localStorage.getItem('user'))
      const firstName = user?.displayName?.split(' ')[0] || 'User'

      const payload = {
        question: dawMode 
          ? `I’m using ${selectedDaw}! ${input}`
          : input,
        identifier: identifier,
        message_history: messageHistory,
        user_name: firstName,
        timestamp: timestampRef.current,
      }

      if (dawMode) {
        payload.daw = selectedDaw

        console.log(selectedDaw);
      }

      const response = await fetch('https://think.philspeiser.com/videochat/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      })

      if (!response.ok) throw new Error('Network response was not ok')

      const reader = response.body.getReader()
      const decoder = new TextDecoder()

      let assistantMessage = { role: 'assistant', type: 'text', content: '' }
      setMessages((prev) => [...prev, assistantMessage])

      let fullContent = ''

      const updateAssistantMessage = (chunk) => {
        fullContent += chunk
        setMessages((prev) => {
          const newMessages = [...prev]
          newMessages[newMessages.length - 1].content = fullContent
          return newMessages
        })
      }

      while (true) {
        const { done, value } = await reader.read()
        if (done) break
        const chunk = decoder.decode(value, { stream: true })
        updateAssistantMessage(chunk)
      }
    } catch (error) {
      console.error('Error:', error)
      const errorMessage = 'Sorry, there was an error processing your request.'
      setMessages((prev) => [...prev, { role: 'assistant', type: 'text', content: errorMessage }])
    } finally {
      setIsLoading(false)
      setDawMode(false)
    }
  }

  const renderMessage = (message, index) => (
    <Box
      key={index}
      sx={{
        mb: 2,
        textAlign: message.role === 'user' ? 'right' : 'left',
      }}
    >
      <Box
        sx={{
          display: 'inline-block',
          p: '0px 10px',
          borderRadius: 1,
          backgroundColor: message.role === 'user' ? notionGrey : lightGrey,
          color: 'text.primary',
        }}
      >
        {(() => {
          switch (message.type) {
            case 'text':
              return (
                <ReactMarkdown
                  components={{
                    code({ node, inline, className, children, ...props }) {
                      const match = /language-(\w+)/.exec(className || '')
                      return !inline && match ? (
                        <SyntaxHighlighter style={dark} language={match[1]} PreTag="div" {...props}>
                          {String(children).replace(/\n$/, '')}
                        </SyntaxHighlighter>
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      )
                    },
                  }}
                >
                  {message.content}
                </ReactMarkdown>
              )
            case 'image':
              return (
                <>
                  {loadingMedia[message.content] && <CircularProgress size={24} />}
                  <img
                    src={message.content}
                    alt="Assistant's image"
                    onLoad={() => setLoadingMedia((prev) => ({ ...prev, [message.content]: false }))}
                    style={{ display: loadingMedia[message.content] ? 'none' : 'block', maxWidth: '100%' }}
                  />
                </>
              )
            default:
              return <Typography>{message.content}</Typography>
          }
        })()}
      </Box>
    </Box>
  )

  return (
    <>
      {!isChatOpen && (
        <Button
        onClick={() => setIsChatOpen(true)}
        variant="contained"
        sx={{
          position: { xs: 'fixed', sm: 'fixed' }, // or just 'fixed' if you want it consistent
          bottom: { xs: '50px', sm: '50px' },
          right: { xs: '20px', sm: '30px' },
          // or left if you want it on the left
          width: { xs: '60px', sm: '60px' },
          height: { xs: '60px', sm: '60px' },
          borderRadius: '60px',
          backgroundColor: '#363636',
          color: offWhite,
          zIndex: 4000, // keep it above other elements
          '&:hover': {
            backgroundColor: '#e600e6',
          },
        }}
      >
        <img src={chatrobo} alt="CHAT BOT" style={{ height: '40px', marginBottom: '0px' }} />
      </Button>
      )}
      {isChatOpen && (
       <Box
       sx={{
         position: 'fixed',
         bottom: { xs: '5%', sm: '5%', xl: '5%' },
         left: { xs: '5%', sm: 'calc(95% - 400px)', xl: 'calc(100% - 500px)' },
         width:  { xs: '90%', sm: '450px' },
         height: { xs: '50%', sm: '600px' },
         zIndex: 4000,
         display: 'flex',
         flexDirection: 'column',
         p: 2,
         backgroundColor: '#191919',
         borderRadius: '8px',
       }}
     >
     
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={() => setIsChatOpen(false)} color="inherit">
              <CloseIcon />
            </IconButton>
          </Box>
          <Paper
  elevation={0}
  ref={messagesContainerRef}
  sx={{
    flexGrow: 1,
    overflowY: 'auto',
    p: 2,
    mb: 2,
    backgroundColor: '#191919',
    maxHeight: '100%', // or a specific px value
  }}
>
            {messages.map((message, index) => renderMessage(message, index))}
            <div ref={messagesEndRef} />
          </Paper>
          <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'background.default', pt: 0 }}>
            <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <TextField
                fullWidth
                variant="outlined"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message here..."
                disabled={isLoading}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
                sx={{
                  backgroundColor: 'background.paper',
                  color: 'text.primary',
                  '&:hover': {
                    backgroundColor: '#2c2c2c',
                  },
                }}
              >
                {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
              </Button>
            </form>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Button
              variant={dawMode ? "contained" : "outlined"}
              onClick={() => setDawMode((prev) => !prev)}
              sx={{ 
                color: dawMode ? offWhite : '#fff',
                backgroundColor: dawMode ? '#ff00ff' : 'transparent',
                minWidth: '100px',
                borderColor: '#ff00ff',
                '&:hover': { backgroundColor: dawMode ? '#cc00cc' : 'rgba(255,0,255,0.2)' }
              }}
            >
              DAW MODE
            </Button>
            {dawMode && (
              <FormControl variant="outlined" size="small" sx={{ minWidth: 120, width: '75%' }}>
                <InputLabel>DAW</InputLabel>
                <Select
                  value={selectedDaw}
                  onChange={(e) => {
                    setSelectedDaw(e.target.value)
                    localStorage.setItem('selectedDaw', e.target.value)
                  }}
                  label="DAW"
                  MenuProps={{
                    disablePortal: true,
                    keepMounted: true,
                    PaperProps: {
                      style: {
                        zIndex: 9999
                      }
                    }
                  }}
                >
                  <MenuItem value="Ableton Live">Ableton Live</MenuItem>
                  <MenuItem value="Apple Logic Pro">Apple Logic Pro</MenuItem>
                  <MenuItem value="Apple GarageBand">Apple GarageBand</MenuItem>
                  <MenuItem value="Avid Pro Tools">Avid Pro Tools</MenuItem>
                  <MenuItem value="Steinberg Cubase">Steinberg Cubase</MenuItem>
                  <MenuItem value="Image-Line FL Studio">Image-Line FL Studio</MenuItem>
                  <MenuItem value="PreSonus Studio One">PreSonus Studio One</MenuItem>
                  <MenuItem value="Bitwig Studio">Bitwig Studio</MenuItem>
                  <MenuItem value="Cockos REAPER">Cockos REAPER</MenuItem>
                  <MenuItem value="Reason Studios Reason">Reason Studios Reason</MenuItem>
                </Select>
              </FormControl>
            )}
            {!dawMode && (
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                Activate DAW MODE before sending to get specific informations based on your DAW (if needed).
                </Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}